function tabsGen () {
   
    function getRouterInfo(){
        const hash = location.hash ? location.hash.slice(1) : '';

        return hash;
    }

    function handleHash() {
        const container = document.querySelectorAll('.genpass__tabs-container');
        const link = document.querySelectorAll('.genpass__tabs-link');
        let tabInfo = getRouterInfo();
        if (tabInfo == '') {
            tabInfo = 'pass';
        }
        let tabs = document.querySelector('#tabs-' + tabInfo);
        let cont = document.querySelector('#cont-' + tabInfo);
       
        for (let x = 0; x < link.length; x++) {
            link[x].classList.remove('active-gener-tabs');
        }
        for (let i = 0; i < container.length; i++) {
            container[i].classList.remove('active-gener-cont');
        }
        
        
        tabs.classList.add('active-gener-tabs');
        cont.classList.add('active-gener-cont');
    }
   
    addEventListener('hashchange', handleHash);
    handleHash();

    
}

module.exports = tabsGen;