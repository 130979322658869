function genpromo () {

    const promoExpr = document.querySelectorAll('.genpass-promo__item-expression');
    const countPromo = document.querySelectorAll('.genpass-promo__item-count');
    let inpNum = document.querySelector('.genpass-promo__item-num');
    let currentNum = document.querySelector('.genpass-promo__item-num').value;
    let inpCountNum = document.querySelector('.genpass-promo__item__count-num');
    let currentCountNum = document.querySelector('.genpass-promo__item__count-num').value;
    const copy = document.querySelector('.genpass-promo__item-copy');
    const arr_en = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    // const arr_EN = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const arrNum = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const promo = document.querySelector('.genpass__item-promo');
    const bntGenPromo = document.querySelector('.genpass-promo__item-btn');


    //  обработчика клика "Длинна промокода "
    for (let i = 0; i < promoExpr.length; i++) {
        promoExpr[i].addEventListener("click", (e) => {
            const target = getCurrentZone(e.target, 'genpass-promo__item-expression');

            if (target.classList.contains('pls')) {
                if (currentNum < 25) {
                    currentNum++;
                }

            }
            if (target.classList.contains('mns')) {
                if (currentNum > 1) {
                    currentNum--;
                }

            }
            inpNum.value = currentNum;
        });

        
    }

 //  обработчика клика "Количество промокода"
    for (let i = 0; i < countPromo.length; i++) {
        countPromo[i].addEventListener('click', (e) => {
            const target = getCurrentZone(e.target, 'genpass-promo__item-count');

            if (target.classList.contains('pls')) {

                currentCountNum++;

            }

            if (target.classList.contains('mns')) {
                if (currentCountNum > 1) {
                    currentCountNum--;

                }

            }
            inpCountNum.value = currentCountNum;
        });

    }

    inpNum.addEventListener('keypress', (e) => {
        if (e.keyCode == 13) {
            let val = document.querySelector('.genpass-promo__item-num').value;
            if (val < 25 ) {
                currentNum = val;
            } else {
                currentNum = 25;
            }
            if (val < 1 ) {
                currentNum =  1;
            }

        }
    });


    inpCountNum.addEventListener('keypress', (e) => {
        if (e.keyCode == 13) {
            let val = document.querySelector('.genpass-promo__item__count-num').value;
            
            if (val < 1) {
                currentCountNum = 1;
            } else {
                currentCountNum = val;
            }

        }
    });

    bntGenPromo.addEventListener('click', () => {
        genPromo();

    });
    function genPromo () {
        const prefix = document.querySelector('.genpass__promo-inp').value;
        let result = [];

        
        promo.innerHTML = '';

        result = result.concat(arr_en);
        result = result.concat(arrNum);

        result.sort(compareRandom);

        for (let k = 0; k < currentCountNum; k++) {
            let out = '';
            currentNum = parseInt(currentNum);

            for (let r = 0; r < currentNum; r++) {
            
                out += result[randomInteger(0, result.length - 1)];

            }
        


            if (result != '') {
                promo.innerHTML += prefix + out + "</br>";

            }

        }
    }

    copy.addEventListener('click', () => {
        
        try {
            // современный объект Selection
            window.getSelection().removeAllRanges();
        } catch (e) {
            // для IE8-
            document.selection.empty();
        }

        if (document.selection) {
            let range = document.body.createTextRange();
            range.moveToElementText(promo);
            range.select().createTextRange();
            document.execCommand("Copy");

        } else if (window.getSelection) {
            let range = document.createRange();
            range.selectNode(promo);
            window.getSelection().addRange(range);
            document.execCommand("Copy");


        }



    });


    function randomInteger(min, max) {
        let rand = min - 0.5 + Math.random() * (max - min);
        rand = Math.round(rand)
        return rand;
    }

    function compareRandom() {
        return Math.random() - 0.5;
    }

    function getCurrentZone(from,to) {
        do {
            if (from.classList.contains(to)) {
                return from;
            }
        } while (from = from.parentElement);

        return null;
    }
}

module.exports = genpromo;