function genpass () {
    const expression = document.querySelectorAll('.genpass__item-expression');
    const countPass = document.querySelectorAll('.genpass__item-count');
    const copy = document.querySelector('.genpass__item-copy');
    const btmGenPass = document.querySelector('.genpass__item-btn');
    const characters = document.querySelector('#characters');
    const letters = document.querySelector('#letters');
    const numbers = document.querySelector('#numbers');
    let pass = document.querySelector('.genpass__item-pass');
    let currentNum = document.querySelector('.genpass__item-num').value;
    let currentCountNum = document.querySelector('.genpass__item__count-num').value;
    let inpCountNum = document.querySelector('.genpass__item__count-num');
    let inpNum = document.querySelector('.genpass__item-num');
    const arr_en = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const arr_EN = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const arrNum = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const arrCharacters = ['!', '@', '#', '$', '%', '&', '*', '<', '>', '?'];

//  обработчика клика "Длинна пароля "
    for (let i = 0; i < expression.length; i++) {
        expression[i].addEventListener("click", (e) =>{
            const target = getCurrentZone(e.target, 'genpass__item-expression');
            
            if (target.classList.contains('pls')) {
                if(currentNum < 25) {
                    currentNum++;
                }
                
            }
            if (target.classList.contains('mns')) {
                if (currentNum > 3) {
                    currentNum--;
                } 

            }
            inpNum.value = currentNum;
        });

        
    }


    //  обработчика клика "Количество паролей"

    for (let i = 0; i < countPass.length; i++) {
        countPass[i].addEventListener('click', (e) => {
            const target = getCurrentZone(e.target, 'genpass__item-count');
            
            if(target.classList.contains('pls')) {
                
                currentCountNum++;
                
            }

            if (target.classList.contains('mns')) {
                if (currentCountNum > 1) {
                    currentCountNum--;
                   
                }

            }
            inpCountNum.value = currentCountNum;
        });

        
    }

    inpNum.addEventListener('keypress', (e) => {
        if (e.keyCode == 13) {
            let val = document.querySelector('.genpass__item-num').value;
            
            if (val < 25) {
                currentNum = val;
            } else {
                currentNum = 25;
            }
            if (val < 1) {
                currentNum = 1;
            }
           
        }
    });


    inpCountNum.addEventListener('keypress', (e) => {
        if (e.keyCode == 13) {
            let val = document.querySelector('.genpass__item__count-num').value;
            
            if (val < 1) {
                currentCountNum = 1;
            } else {
                currentCountNum = val;
            }

            
        }
    });

    btmGenPass.addEventListener('click', () => {
        genPass();

    });
    
    function genPass () {
        let result = [];
       
        pass.innerHTML = '';


        if(letters.checked) {
            result = result.concat(arr_en);
            result = result.concat(arr_EN);
        }
        if (numbers.checked) {
            result = result.concat(arrNum);
        }
        if (characters.checked) {
            result = result.concat(arrCharacters);
        }
        if (!characters.checked && !numbers.checked && !letters.checked) {
           
            result = [];
            
        }

       
        result.sort(compareRandom);

        
        for (let k = 0; k < currentCountNum; k++) {
            let out = '';
            currentNum = parseInt(currentNum);
           
           

            for (let r = 0; r < currentNum; r++) {
                
                out += result[randomInteger(0, result.length - 1)];
                
            }
            

            
            if (result != '') { 
                pass.innerHTML += out + "</br>";
                
            }

        }
       
    }

    copy.addEventListener('click', () => {
        // console.log(pass);
        // pass.select();
        // document.execCommand('copy'); 
        try {
            // современный объект Selection
            window.getSelection().removeAllRanges();
        } catch (e) {
            // для IE8-
            document.selection.empty();
        }

        if (document.selection) {
            let range = document.body.createTextRange();
            range.moveToElementText(pass);
            range.select().createTextRange();
            document.execCommand("Copy");

        } else if (window.getSelection) {
            let range = document.createRange();
            range.selectNode(pass);
            window.getSelection().addRange(range);
            document.execCommand("Copy");


        }



    });

    function randomInteger(min, max) {
        let rand = min - 0.5 + Math.random() * (max - min);
        rand = Math.round(rand)
        return rand;
    }


    function compareRandom () {
        return Math.random() - 0.5;
    }
    function getCurrentZone(from, to) {
        do {
            if (from.classList.contains(to)) {
                return from;
            }
        } while (from = from.parentElement);

        return null;
    }
}
module.exports = genpass;